<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title pt-3 px-3 d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4>
                <router-link :to="{ name: 'slider' }">
                  <i class="fas fa-arrow-left"></i>
                </router-link>
                &nbsp; Slider types
              </h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard </router-link>\
                <router-link :to="{ name: 'slider' }"> Slider </router-link> \ Slider
                types
              </div>
            </div>
            <div class="breadcrumb-right">
              <v-btn @click="addType()" class="mt-4 btn btn-primary mr-1" dark>
                <i class="fa fa-plus"></i>
                Add slider type
              </v-btn>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="">
            <v-skeleton-loader v-if="loading" type="table-thead"> </v-skeleton-loader>

            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
            </v-skeleton-loader>
            <table class="table" v-if="!loading">
              <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column">Title</th>
                  <th class="px-3 wrap-column">Status</th>
                  <th class="px-3 text-center">Action</th>
                </tr>
              </thead>
              <tbody v-if="types.length > 0">
                <tr v-for="(type, index) of types" :key="index">
                  <td class="px-3 wrap-column">{{ type.title }}</td>
                  <td class="px-3 pt-2 wrap-column">
                    <span
                      class="badge text-lg`"
                      v-bind:class="{
                        'badge-success': type.is_active,
                        'badge-danger': !type.is_active,
                      }"
                      >{{ type.is_active ? "Active" : "Inactive" }}</span
                    >
                  </td>
                  <td class="px-2 text-center">
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i> </span
                        ></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text
                          tag="div"
                          class="navi-item"
                          v-if="checkIsAccessible('slider', 'edit')"
                        >
                          <a href="#" class="navi-link" @click="editSliderType(type)">
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text
                          tag="div"
                          class="navi-item"
                          v-if="checkIsAccessible('slider', 'delete')"
                        >
                          <a
                            href="#"
                            class="navi-link"
                            @click="deleteSliderType(type.id)"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3" class="text-center">
                    <strong>
                      No data available to display.
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <create-and-update
      ref="slider-type"
      :dialog="dialog"
      @refresh_list="getTypes"
    ></create-and-update>
  </div>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";

import SliderTypeService from "@/core/services/slider/type/SliderTypeService";

const typeService = new SliderTypeService();

export default {
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      types: [],
      dialog: false,
      loading: false,
      type: {},
    };
  },
  mounted() {
    this.getTypes();
  },
  methods: {
    getTypes() {
      this.dialog = false;
      this.loading = true;
      typeService.paginate().then((response) => {
        this.types = response.data;
        this.loading = false;
      });
    },
    addType() {
      this.$refs["slider-type"].createType();
    },
    editSliderType(type) {
      this.$refs["slider-type"].setType(type);
    },
    deleteSliderType(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            typeService.delete(id).then((response) => {
              this.getTypes();
            });
          }
        },
      });
    },
  },
};
</script>
